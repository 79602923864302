import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { MODEL_DEAL, MODEL_SERVICE_CALL, MODEL_TASK } from 'constants/models';
import {
  FIELD_CONTACT as FIELD_SELECT_DEAL_CONTACT,
  FIELD_DEAL as FIELD_SELECT_DEAL_DEAL,
  FIELD_NAME as FIELD_SELECT_DEAL_NAME,
  FIELD_SYSTEM as FIELD_SELECT_DEAL_SYSTEM,
} from 'modules/Deal/constants/selectDealFields';
import serviceCallApi from 'modules/ServiceCall/api/serviceCall';
import { INITIAL_VALUES as SERVICE_CALL_INITIAL_VALUES } from 'modules/ServiceCall/components/ServiceCallForm';
import taskApi from 'modules/Task/api/task';
import { INITIAL_VALUES as TASK_INITIAL_VALUES } from 'modules/Task/components/TaskForm';
import dayjs from 'utils/dayjs';

import dispatchingApi from '../api/dispatching';
import { INITIAL_VALUES as EVENT_FORM_INITIAL_VALUES } from '../components/EventForm';
import {
  FIELD_CONTACT,
  FIELD_MODEL,
  FIELD_MODEL_ID,
  FIELD_NOTES,
  FIELD_SYSTEM,
  FIELD_TITLE,
} from '../constants/fields';

const initialState = {
  selectedDate: dayjs().utc().format(),
  unassignedItems: [],
  partialEvent: null,
  selectingModel: false,
  selectingDeal: false,
  editingServiceCall: null,
  editingTask: null,
  viewingEvent: null,
  editingEvent: null,
};

const slice = createSlice({
  name: 'dispatching',
  initialState,
  reducers: {
    selectDate: (state, action) => ({
      ...state,
      selectedDate: action.payload.selectedDate,
    }),

    viewEvent: (state, action) => ({
      ...state,
      viewingEvent: action.payload,
    }),

    cancelViewingEvent: (state) => ({
      ...state,
      viewingEvent: null,
    }),

    editEvent: (state, action) => ({
      ...state,
      viewingEvent: null,
      editingEvent: action.payload,
    }),

    changeEditingServiceCall: (state, action) => ({
      ...state,
      editingServiceCall: { ...state.editingServiceCall, ...action.payload },
    }),

    changeEditingTask: (state, action) => ({
      ...state,
      editingTask: { ...state.editingTask, ...action.payload },
    }),

    showSelectModel: (state, action) => ({
      ...state,
      selectingModel: true,
      partialEvent: {
        ...action.payload,
        [FIELD_MODEL]: null,
      },
    }),

    completeSelectModel: (state, action) => ({
      ...state,
      selectingModel: false,
      partialEvent: {
        ...state.partialEvent,
        [FIELD_MODEL]: action.payload,
      },
    }),

    cancelSelectModel: (state) => ({
      ...state,
      partialEvent: null,
      selectingModel: false,
    }),

    showSelectDeal: (state) => ({
      ...state,
      partialEvent: {
        ...state.partialEvent,
        [FIELD_MODEL]: MODEL_DEAL,
      },
      selectingDeal: true,
    }),

    completeSelectDeal: (state, action) => ({
      ...state,
      partialEvent: null,
      editingEvent: {
        ...EVENT_FORM_INITIAL_VALUES,
        ...state.partialEvent,
        [FIELD_CONTACT]: action.payload[FIELD_SELECT_DEAL_CONTACT],
        [FIELD_MODEL_ID]: action.payload[FIELD_SELECT_DEAL_DEAL],
        [FIELD_SYSTEM]: action.payload[FIELD_SELECT_DEAL_SYSTEM],
        [FIELD_TITLE]: action.payload[FIELD_SELECT_DEAL_NAME],
      },
      selectingDeal: false,
    }),

    cancelSelectDeal: (state) => ({
      ...state,
      partialEvent: null,
      selectingDeal: null,
    }),

    cancelEditingEvent: (state) => ({
      ...state,
      partialEvent: null,
      editingEvent: null,
    }),

    showEditingServiceCall: (state, action) => ({
      ...state,
      partialEvent: {
        ...state.partialEvent,
        ...action.payload,
      },
      editingServiceCall: SERVICE_CALL_INITIAL_VALUES,
    }),

    cancelEditingServiceCall: (state) => ({
      ...state,
      partialEvent: null,
      editingServiceCall: null,
    }),

    showEditingTask: (state, action) => ({
      ...state,
      partialEvent: {
        ...state.partialEvent,
        ...action.payload,
      },
      editingTask: TASK_INITIAL_VALUES,
    }),

    cancelEditingTask: (state) => ({
      ...state,
      partialEvent: null,
      editingTask: null,
    }),
  },
  extraReducers: (builder) => {
    builder
      // Event created or updated successfully
      .addMatcher(
        isAnyOf(
          dispatchingApi.endpoints.create.matchFulfilled,
          dispatchingApi.endpoints.update.matchFulfilled,
        ),
        (state) => ({
          ...state,
          partialEvent: null,
          editingEvent: null,
        }),
      )
      // Service Call created or updated successfully
      .addMatcher(
        isAnyOf(
          serviceCallApi.endpoints.create.matchFulfilled,
          serviceCallApi.endpoints.update.matchFulfilled,
        ),
        (state, action) => ({
          ...state,
          partialEvent: null,
          editingServiceCall: null,
          editingEvent: {
            ...EVENT_FORM_INITIAL_VALUES,
            ...state.partialEvent,
            [FIELD_MODEL]: MODEL_SERVICE_CALL,
            [FIELD_MODEL_ID]: action.payload.id,
            [FIELD_TITLE]: action.payload.title,
            [FIELD_NOTES]: action.payload.issue,
          },
        }),
      )
      // Task created or updated successfully
      .addMatcher(
        isAnyOf(
          taskApi.endpoints.create.matchFulfilled,
          taskApi.endpoints.update.matchFulfilled,
        ),
        (state, action) => ({
          ...state,
          partialEvent: null,
          editingTask: null,
          editingEvent: {
            ...EVENT_FORM_INITIAL_VALUES,
            ...state.partialEvent,
            [FIELD_MODEL]: MODEL_TASK,
            [FIELD_MODEL_ID]: action.payload.id,
            [FIELD_TITLE]: action.payload.title,
            [FIELD_NOTES]: action.payload.description,
          },
        }),
      );
  },
});

export default slice;
