export const STATUS_OPEN = 'Open';
export const STATUS_CLOSED = 'Closed';
export const STATUS_RESOLVED = 'Resolved';
export const STATUS_ON_HOLD = 'On Hold';

export const STATUS_OPTIONS = [
  { label: 'Open', value: STATUS_OPEN },
  { label: 'Closed', value: STATUS_CLOSED },
  { label: 'Resolved', value: STATUS_RESOLVED },
  { label: 'On Hold', value: STATUS_ON_HOLD },
];
