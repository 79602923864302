/* eslint-disable react/jsx-filename-extension */
/* eslint-disable camelcase */
/* eslint-disable react/no-multi-comp */
import {
  colors,
  createMuiTheme,
  makeStyles,
  MuiThemeProvider,
} from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import React, { Suspense, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { geolocated } from 'react-geolocated';
import { Redirect, Route, Switch } from 'react-router-dom';

import Page from 'components/Page';
import { pageHandler } from 'utils/quickFunc';
import getDefaultTimezone from 'utils/timezone/getDefaultTimezone';
import setDefaultTimezone from 'utils/timezone/setDefaultTimezone';

import 'assets/scss/style.scss';
import '../../node_modules/font-awesome/scss/font-awesome.scss';
import ErrorBoundary from '../Containers/Other/ErrorClass';
import routes from '../Routes/route';
import history from '../store/history';
import ScrollToTop from './components/ScrollToTop';

setDefaultTimezone(getDefaultTimezone());

const AdminLayout = React.lazy(() => import('./layout/AdminLayout'));
const Error404 = React.lazy(() => import('../Containers/Other/401'));

const menu = routes.map((route, index) => {
  return route.component ? (
    <Route
      exact={route.exact}
      key={index}
      name={route.name}
      path={route.path}
      render={(props) => (
        <ErrorBoundary {...props}>
          <route.component {...props} />
        </ErrorBoundary>
      )}
    />
  ) : (
    <Error404 />
  );
});

const checkAuth = () => {
  const token = localStorage.getItem('token');

  if (!token) {
    return false;
  }

  return true;
};

const AuthRoute = ({ component: Component, ...rest }) => {
  const success = async () => {
    //
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      paddingBottom: 100,
    },
  }));
  const classes = useStyles();

  React.useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    history.location.pathname !== '/login' &&
      navigator.geolocation.getCurrentPosition(success, () => '', {
        enableHighAccuracy: true,
        maximumAge: 0,
      });

    history.listen((location) => {
      const options = {
        enableHighAccuracy: true,
        maximumAge: 0,
      };
      if (location.pathname !== '/login') {
        navigator.geolocation.getCurrentPosition(success, () => '', options);
      }
    });

    localStorage.setItem('prevPath', history.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname]);

  return (
    <Fade>
      <Route
        {...rest}
        render={(props) =>
          checkAuth() ? (
            <Page className={classes.root} title={pageHandler()}>
              <Component {...props} {...rest} />{' '}
            </Page>
          ) : (
            <Redirect to="/" />
          )
        }
      />
    </Fade>
  );
};

const lightTheme = createMuiTheme({
  overrides: {
    MuiInputBase: {
      input: {
        '&::placeholder': {
          opacity: 1,
          color: colors.blueGrey[600],
        },
      },
    },
  },
  palette: {
    type: 'light',
    action: {
      active: colors.blueGrey[600],
    },
    background: {
      default: colors.common.white,
      paper: colors.common.white,
    },
    primary: {
      main: '#5A99D7',
    },
    secondary: {
      main: '#5A99D7',
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600],
    },
  },
  typography: {
    h1: {
      fontWeight: 500,
      fontSize: 35,
      letterSpacing: '-0.24px',
    },
    h2: {
      fontWeight: 500,
      fontSize: 29,
      letterSpacing: '-0.24px',
    },
    h3: {
      fontWeight: 500,
      fontSize: 24,
      letterSpacing: '-0.06px',
    },
    h4: {
      fontWeight: 500,
      fontSize: 20,
      letterSpacing: '-0.06px',
    },
    h5: {
      fontWeight: 500,
      fontSize: 16,
      letterSpacing: '-0.05px',
    },
    h6: {
      fontWeight: 500,
      fontSize: 14,
      letterSpacing: '-0.05px',
    },
    p: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '1.5em',
    },
    overline: {
      fontWeight: 500,
    },
  },
});

const App = () => {
  const [theme, setTheme] = useState('light');

  const toggleDarkTheme = () => {
    const newPaletteType = theme === 'light' ? 'light' : 'dark';
    setTheme(newPaletteType);
    sessionStorage.setItem('theme2', JSON.stringify(newPaletteType));
  };

  React.useEffect(() => {
    localStorage.removeItem('theme');
  }, []);

  React.useEffect(() => {
    const savedTheme = sessionStorage.getItem('theme2');
    if (savedTheme) {
      setTheme({
        palette: {
          type: JSON.parse(savedTheme),
        },
      });
    }
  }, []);

  return (
    <ScrollToTop>
      <MuiThemeProvider theme={lightTheme}>
        <Suspense fallback={<Spinner />}>
          <Switch>
            {menu}
            <AuthRoute
              component={AdminLayout}
              path="/"
              setTheme={toggleDarkTheme}
              theme={theme}
            />
          </Switch>
        </Suspense>
      </MuiThemeProvider>
    </ScrollToTop>
  );
};

export default geolocated({
  positionOptions: {
    enableHighAccuracy: true,
  },
  userDecisionTimeout: 5000,
})(App);
