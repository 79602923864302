import { createApi } from '@reduxjs/toolkit/query/react';

import {
  MODEL_PLURAL_NAME_TO_SINGULAR,
  MODELS_PLURAL_NAMES,
} from 'constants/models';
import EventService from 'Services/EventService';
import UserService from 'Services/UserService';
import axiosBaseQuery from 'utils/axios/axiosBaseQuery';
import { getErrorMessage } from 'utils/axios/getErrorMessage';
import dayjs from 'utils/dayjs';
import { RequestValidationFailedError } from 'utils/errors';
import getDefaultTimezone from 'utils/timezone/getDefaultTimezone';

export default createApi({
  reducerPath: 'dispatchingApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    list: build.query({
      queryFn: async (date, { signal }) => {
        const startOfDay = dayjs.tz(date, getDefaultTimezone()).startOf('day');
        const endOfDay = dayjs.tz(date, getDefaultTimezone()).endOf('day');

        const params = {
          filters: [
            {
              name: 'where_start',
              search_sign: '>=',
              value: startOfDay.toISOString(),
            },

            {
              name: 'where_start',
              search_sign: '<=',
              value: endOfDay.toISOString(),
            },
          ],
        };

        return { data: await EventService.list(params, { signal }) };
      },
      providesTags: ['DispatchingList'],
    }),

    listUnassigned: build.query({
      queryFn: async (params, { signal }) => {
        const data = await EventService.listUnassigned({ signal });

        return {
          data: MODELS_PLURAL_NAMES.reduce(
            (result, modelPlural) => [
              ...result,
              ...data[modelPlural].map(({ model_id: modelId, ..._data }) => ({
                id: modelId,
                model_id: modelId,
                model: MODEL_PLURAL_NAME_TO_SINGULAR[modelPlural],
                ..._data,
              })),
            ],
            [],
          ),
        };
      },
      providesTags: ['DispatchingUnassignedList'],
    }),

    get: build.query({
      queryFn: async (id, { signal }) => {
        return { data: await EventService.get(id, { signal }) };
      },
      providesTags: ['DispatchingGet'],
    }),

    users: build.query({
      queryFn: async (params, { signal }) => {
        return { data: await UserService.getTechnicians({ signal }) };
      },
      providesTags: ['DispatchingUsers'],
    }),

    create: build.mutation({
      queryFn: async (data, { signal }) => {
        try {
          return { data: await EventService.create(data, { signal }) };
        } catch (error) {
          throw new RequestValidationFailedError(getErrorMessage(error));
        }
      },
      invalidatesTags: ['DispatchingList', 'DispatchingUnassignedList'],
    }),

    update: build.mutation({
      queryFn: async ({ id, ...data }, { signal }) => {
        try {
          return { data: await EventService.update(id, data, { signal }) };
        } catch (error) {
          throw new RequestValidationFailedError(getErrorMessage(error));
        }
      },
      invalidatesTags: ['DispatchingList', 'DispatchingGet'],
    }),
  }),
});
