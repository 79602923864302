export const FIELD_ID = 'id';
export const FIELD_CONTACT = 'contact_id';
export const FIELD_SITE = 'site_id';
export const FIELD_COMPANY = 'company_id';
export const FIELD_SYSTEM = 'deal_system_id';
export const FIELD_HOURLY_CHARGE = 'hourly_charge';
export const FIELD_TOTAL_FEE = 'total_fee';
export const FIELD_TITLE = 'title';
export const FIELD_REASON = 'reason';
export const FIELD_TAXABLE = 'taxable';
export const FIELD_WARRANTY = 'warranty';
