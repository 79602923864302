import { useCallback, memo, useMemo, useState } from 'react';
import { TextField, Autocomplete, Box, Avatar, Stack } from '@mui/material';
import { useQuery } from 'react-query';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';

import useCatchError from 'utils/axios/useCatchError';

const DEFAULT_OPTIONS_PARAMS = {};

/**
 * Input with autocomplete that provide options loading via React Query.
 * {@link ../../docs/components/InputAutocompleteQuery.md Documentation}
 *
 * @param {Object} props Props object
 * @param {String} props.queryKeyPrefix Prefix using by React Query for caching options
 * @param {String} props.label Text above input field
 * @param {Boolean} props.required Flag makes input required (default: `false`)
 * @param {Boolean} props.disabled Flag makes input disabled (default: `false`)
 * @param {Boolean} props.multiple Flag makes field as disabled (default: `false`)
 * @param {Boolean} props.grouped Flag makes field grouped (default: `false`)
 * @param {Boolean} props.error Flag turns input in error state (default: `false`)
 * @param {Number} props.inputDelay Delay between input and request options (default: `300`)
 * @param {String} props.placeholder Text displayed when input focused
 * @param {String} props.loadingText Text displayed when input in loading state
 * @param {String} props.helperText Text displayed under the input
 * @param {String|Number|Array.<String|Number>} props.value Value of the input
 * @param {Function|Array.<{ label: string, value: string|number }>} props.options Function for fetch options or array of options
 * @param {(value: String|Number|Array.<String|Number>, option: object) => void} props.onChange Callback function that returns selected input value
 * @returns {React.Element}
 */
const InputAutocompleteQuery = ({
  queryKeyPrefix,
  label = 'Select',
  required = false,
  disabled = false,
  multiple = false,
  grouped = false,
  error = false,
  inputDelay = 300,
  placeholder = null,
  loadingText = 'Loading…',
  options: optionsProp,
  optionsParams = DEFAULT_OPTIONS_PARAMS,
  helperText,
  value,
  onChange,
  ...props
}) => {
  // State

  const [inputValue, setInputValue] = useState('');
  const [opened, setOpened] = useState(false);
  const [cachedOptions, setCachedOptions] = useState([]);

  // Hooks

  const catchError = useCatchError();

  const { data: options, isFetching } = useQuery({
    queryFn: async ({ signal }) => {
      if (optionsProp instanceof Function) {
        return optionsProp(value, inputValue, optionsParams, signal);
      }

      return optionsProp;
    },
    queryKey: [queryKeyPrefix, opened ? inputValue : value, optionsParams],
    placeholderData: [],
    keepPreviousData: true,
    onError: catchError,
  });

  // Methods

  const getOptionLabel = useCallback((option) => option.label, []);

  // Handlers

  const handleAutocompleteChange = useCallback(
    (event, _value) => {
      if (multiple) {
        onChange(
          _value.map(({ value: itemValue }) => itemValue),
          _value,
        );

        return;
      }

      onChange(_value?.value ?? null, _value);
    },
    [multiple, onChange],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleInputChange = useCallback(
    debounce((event, _inputValue) => setInputValue(_inputValue), inputDelay),
    [],
  );

  const handleOpen = useCallback(() => {
    setCachedOptions(
      options.filter(({ value: _value }) => {
        if (multiple) {
          return value.includes(_value);
        }

        return value === _value;
      }),
    );
    setOpened(true);
  }, [multiple, options, value]);

  const handleClose = useCallback(() => {
    setOpened(false);
    setCachedOptions([]);
    setInputValue('');
  }, []);

  // Computable

  const autocompleteOptions = useMemo(() => {
    const cachedOptionsValues = cachedOptions.map(
      ({ value: _value }) => _value,
    );

    return [
      ...cachedOptions,
      ...options.filter(
        ({ value: _value }) => !cachedOptionsValues.includes(_value),
      ),
    ];
  }, [cachedOptions, options]);

  const autocompleteValue = useMemo(() => {
    if (multiple) {
      if (!value) {
        return [];
      }

      if (!(value instanceof Array)) {
        throw new Error(
          'InputAutocompleteQuery in multiple mode requires value to be an array.',
        );
      }

      return autocompleteOptions.filter((_option) =>
        value.includes(_option.value),
      );
    }

    if (!value) {
      return null;
    }

    return (
      autocompleteOptions.find((_option) => _option.value === value) ?? null
    );
  }, [autocompleteOptions, multiple, value]);

  const groupBy = useMemo(() => {
    if (grouped) {
      return (option) => option.group;
    }

    return null;
  }, [grouped]);

  const renderOption = useCallback(
    ({ key, ..._props }, option) => (
      <Stack
        // eslint-disable-next-line react/jsx-props-no-spreading
        {..._props}
        key={_props.id}
        direction="row"
        component="li"
        gap={2}
        justifyItems="center"
        data-testid="input_autocomplete_query_option"
        data-icon={option.icon}
        data-label={option.label}
        data-value={option.value}
      >
        {option.icon && (
          <Avatar alt={option.label} src={option.icon} variant="square" />
        )}
        <Box
          component="span"
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            flexGrow: 1,
            whiteSpace: 'nowrap',
          }}
        >
          {option.label}
        </Box>
      </Stack>
    ),
    [],
  );

  const renderInput = useCallback(
    (params) => (
      <TextField
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...params}
        fullWidth={true}
        variant="outlined"
        label={label}
        error={error}
        helperText={helperText}
        disabled={disabled}
        InputLabelProps={{
          required,
        }}
        inputProps={{
          ...params.inputProps,
          'data-value': JSON.stringify(value),
        }}
        placeholder={isFetching ? loadingText : placeholder}
      />
    ),
    [
      disabled,
      error,
      helperText,
      isFetching,
      label,
      loadingText,
      placeholder,
      required,
      value,
    ],
  );

  // Render

  return (
    <Autocomplete
      fullWidth={true}
      autoComplete={false}
      autoSelect={false}
      clearOnBlur={true}
      // eslint-disable-next-line react/destructuring-assignment
      data-testid={props['data-testid'] ?? 'input_autocomplete_query'}
      data-value={JSON.stringify(value)}
      loading={isFetching}
      options={autocompleteOptions}
      multiple={multiple}
      disabled={disabled}
      filterSelectedOptions={multiple}
      value={autocompleteValue}
      renderInput={renderInput}
      renderOption={renderOption}
      getOptionLabel={getOptionLabel}
      groupBy={groupBy}
      onChange={handleAutocompleteChange}
      onOpen={handleOpen}
      onClose={handleClose}
      onInputChange={handleInputChange}
    />
  );
};

InputAutocompleteQuery.propTypes = {
  queryKeyPrefix: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  grouped: PropTypes.bool,
  error: PropTypes.bool,
  placeholder: PropTypes.string,
  loadingText: PropTypes.string,
  helperText: PropTypes.string,
  // eslint-disable-next-line no-useless-computed-key
  ['data-testid']: PropTypes.string,
  inputDelay: PropTypes.number,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf([PropTypes.string, PropTypes.number]),
    PropTypes.array,
  ]),
  options: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
        grouped: PropTypes.bool,
      }),
    ),
  ]).isRequired,
  optionsParams: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

/**
 * @type {InputAutocompleteQuery}
 */
export default memo(InputAutocompleteQuery);
