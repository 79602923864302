/* eslint-disable react/jsx-props-no-spreading */
import { Alert, Box, Button, Grid, TextField } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import PropTypes from 'prop-types';
import { memo, useCallback, useMemo } from 'react';

import CheckboxField from 'components/CheckboxField';
import InputAutocompleteQuery from 'components/InputAutocompleteQuery';
import useForm from 'hooks/useForm';
import { COLOR_ERROR, COLOR_PRIMARY } from 'utils/constants/colors';
import dayjs from 'utils/dayjs';

import {
  FIELD_END,
  FIELD_ID,
  FIELD_MODEL_ID,
  FIELD_NOTES,
  FIELD_NOTIFY_CUSTOMER,
  FIELD_NOTIFY_TECH,
  FIELD_SEPARATE_EVENT_PER_TECH,
  FIELD_START,
  FIELD_SYSTEM,
  FIELD_TECHNICIAN_USERS,
  FIELD_TITLE,
} from '../constants/fields';

export const INITIAL_VALUES = {
  [FIELD_TITLE]: '',
  [FIELD_NOTES]: '',
  [FIELD_TECHNICIAN_USERS]: [],
  [FIELD_SYSTEM]: null,
  [FIELD_START]: null,
  [FIELD_END]: null,
  [FIELD_NOTIFY_CUSTOMER]: true,
  [FIELD_NOTIFY_TECH]: true,
  [FIELD_SEPARATE_EVENT_PER_TECH]: false,
};

const EventForm = ({
  submitting = false,
  cancelLabel = 'Cancel',
  submitLabel = 'Submit',
  submitLoadingLabel = 'Submitting…',
  errorMessage = null,
  values: initialValues = INITIAL_VALUES,
  errors: initialErrors = {},
  techniciansFieldOptions = [],
  systemFieldOptions = [],
  canSelectSystem = false,
  canNotifyCustomer = false,
  canNotifyTech = false,
  onSubmit,
  onCancel,
}) => {
  // Hooks

  const form = useForm({
    initialValues,
    initialErrors,
    onSubmit,
  });

  // Handlers

  const handleCancelClick = useCallback(() => onCancel(), [onCancel]);

  // Computable

  const startMinDate = useMemo(() => {
    if (form.values[FIELD_ID]) {
      return null;
    }

    return dayjs().tz().startOf('day');
  }, [form.values]);

  const endMinDate = useMemo(() => {
    if (form.values[FIELD_ID]) {
      return null;
    }

    return form.values[FIELD_START]
      ? dayjs(form.values[FIELD_START]).tz()
      : dayjs().tz().startOf('day');
  }, [form.values]);

  const submitDisabled = useMemo(() => {
    if (submitting) {
      return true;
    }

    return (
      !form.values[FIELD_TITLE] ||
      !form.values[FIELD_TECHNICIAN_USERS]?.length ||
      !form.values[FIELD_START] ||
      !form.values[FIELD_END]
    );
  }, [form.values, submitting]);

  const systemFieldOptionsParams = useMemo(
    () => ({ deal_id: form.values[FIELD_MODEL_ID] }),
    [form.values],
  );

  const canCreateSeparateEventsPerTech = useMemo(
    () =>
      !initialValues[FIELD_ID] &&
      form.values[FIELD_TECHNICIAN_USERS]?.length > 1,
    [form.values, initialValues],
  );

  // Render

  return (
    <Box component="form" onSubmit={form.handleSubmit}>
      <Grid container={true} spacing={2}>
        {errorMessage && (
          <Grid item={true} md={12} xs={12}>
            <Alert severity={COLOR_ERROR}>{errorMessage}</Alert>
          </Grid>
        )}
        <Grid item={true} md={12} xs={12}>
          <TextField
            required={true}
            fullWidth={true}
            disabled={submitting}
            label="Title"
            variant="outlined"
            inputProps={{
              'data-testid': 'title_field',
            }}
            {...form.getFieldProps(FIELD_TITLE)}
            {...form.getMuiFieldErrorProps(FIELD_TITLE)}
          />
        </Grid>
        <Grid item={true} md={12} xs={12}>
          <TextField
            fullWidth={true}
            multiline={true}
            disabled={submitting}
            rows={4}
            label="Notes"
            variant="outlined"
            inputProps={{
              'data-testid': 'notes_field',
            }}
            {...form.getFieldProps(FIELD_NOTES)}
            {...form.getMuiFieldErrorProps(FIELD_NOTES)}
          />
        </Grid>
        {canSelectSystem && (
          <Grid item={true} md={12} xs={12}>
            <InputAutocompleteQuery
              disabled={submitting}
              queryKeyPrefix="event_form_systems"
              label="System"
              placeholder="Search System…"
              options={systemFieldOptions}
              optionsParams={systemFieldOptionsParams}
              value={form.values[FIELD_SYSTEM] ?? null}
              onChange={form.getFieldValueChangeHandler(FIELD_SYSTEM)}
              {...form.getMuiFieldErrorProps(FIELD_SYSTEM)}
              data-testid="system_field"
            />
          </Grid>
        )}
        <Grid item={true} md={12} xs={12}>
          <InputAutocompleteQuery
            disabled={submitting}
            required={true}
            multiple={true}
            queryKeyPrefix="event_form_technicians"
            label="Technicians"
            placeholder="Search Technicians…"
            options={techniciansFieldOptions}
            value={form.values[FIELD_TECHNICIAN_USERS] ?? []}
            onChange={form.getFieldValueChangeHandler(FIELD_TECHNICIAN_USERS)}
            {...form.getMuiFieldErrorProps(FIELD_TECHNICIAN_USERS)}
            data-testid="technicians_field"
          />
        </Grid>
        <Grid item={true} md={6} xs={12}>
          <DateTimePicker
            disabled={submitting}
            slotProps={{
              textField: {
                fullWidth: true,
                required: true,
                ...form.getMuiFieldErrorProps(FIELD_START),
                inputProps: {
                  'data-testid': 'start_field',
                },
              },
            }}
            label="Start date"
            minDate={startMinDate}
            value={
              form.values[FIELD_START]
                ? dayjs(form.values[FIELD_START]).tz()
                : null
            }
            onChange={form.getFieldValueChangeHandler(FIELD_START, (value) =>
              value.tz().utc().format(),
            )}
          />
        </Grid>
        <Grid item={true} md={6} xs={12}>
          <DateTimePicker
            disabled={submitting}
            slotProps={{
              textField: {
                fullWidth: true,
                required: true,
                ...form.getMuiFieldErrorProps(FIELD_END),
                inputProps: {
                  'data-testid': 'end_field',
                },
              },
            }}
            label="End date"
            value={
              form.values[FIELD_END] ? dayjs(form.values[FIELD_END]).tz() : null
            }
            minDate={endMinDate}
            onChange={form.getFieldValueChangeHandler(FIELD_END, (value) =>
              value.tz().utc().format(),
            )}
          />
        </Grid>
        {canNotifyCustomer && (
          <Grid item={true} md={6} xs={12}>
            <CheckboxField
              disabled={submitting}
              label="Notify Customer"
              value={form.values[FIELD_NOTIFY_CUSTOMER] ?? false}
              onChange={form.getFieldValueChangeHandler(FIELD_NOTIFY_CUSTOMER)}
              {...form.getMuiFieldErrorProps(FIELD_NOTIFY_CUSTOMER)}
              data-testid="notify_customer_field"
            />
          </Grid>
        )}
        {canNotifyTech && (
          <Grid item={true} md={6} xs={12}>
            <CheckboxField
              disabled={submitting}
              label="Notify Tech"
              value={form.values[FIELD_NOTIFY_TECH] ?? false}
              onChange={form.getFieldValueChangeHandler(FIELD_NOTIFY_TECH)}
              {...form.getMuiFieldErrorProps(FIELD_NOTIFY_TECH)}
              data-testid="notify_tech_field"
            />
          </Grid>
        )}
        {canCreateSeparateEventsPerTech && (
          <Grid item={true} md={12} xs={12}>
            <CheckboxField
              disabled={submitting}
              label="Create separate event for each technician"
              value={form.values[FIELD_SEPARATE_EVENT_PER_TECH] ?? false}
              onChange={form.getFieldValueChangeHandler(
                FIELD_SEPARATE_EVENT_PER_TECH,
              )}
              {...form.getMuiFieldErrorProps(FIELD_SEPARATE_EVENT_PER_TECH)}
              data-testid="separate_per_tech"
            />
          </Grid>
        )}
        <Grid
          item={true}
          md={12}
          xs={12}
          display="flex"
          gap={2}
          justifyContent="space-between"
        >
          <Button
            disabled={submitting}
            type="button"
            variant="outlined"
            color={COLOR_PRIMARY}
            onClick={handleCancelClick}
            data-testid="cancel_button"
          >
            {cancelLabel}
          </Button>
          <Button
            disabled={submitDisabled}
            type="submit"
            variant="contained"
            color={COLOR_PRIMARY}
            data-testid="submit_button"
          >
            {submitting ? submitLoadingLabel : submitLabel}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

EventForm.propTypes = {
  submitting: PropTypes.bool,
  cancelLabel: PropTypes.string,
  submitLabel: PropTypes.string,
  submitLoadingLabel: PropTypes.string,
  errorMessage: PropTypes.string,
  values: PropTypes.shape({
    [FIELD_TITLE]: PropTypes.string,
    [FIELD_NOTES]: PropTypes.string,
    [FIELD_TECHNICIAN_USERS]: PropTypes.arrayOf(PropTypes.number),
    [FIELD_SYSTEM]: PropTypes.number,
    [FIELD_START]: PropTypes.string,
    [FIELD_END]: PropTypes.string,
    [FIELD_NOTIFY_CUSTOMER]: PropTypes.bool,
    [FIELD_NOTIFY_TECH]: PropTypes.bool,
  }),
  techniciansFieldOptions: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
      }),
    ),
  ]),
  systemFieldOptions: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
      }),
    ),
  ]),
  errors: PropTypes.shape({
    [FIELD_TITLE]: PropTypes.string,
    [FIELD_NOTES]: PropTypes.string,
    [FIELD_TECHNICIAN_USERS]: PropTypes.string,
    [FIELD_SYSTEM]: PropTypes.string,
    [FIELD_START]: PropTypes.string,
    [FIELD_END]: PropTypes.string,
    [FIELD_NOTIFY_CUSTOMER]: PropTypes.string,
    [FIELD_NOTIFY_TECH]: PropTypes.string,
  }),
  canSelectSystem: PropTypes.bool,
  canNotifyCustomer: PropTypes.bool,
  canNotifyTech: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

/**
 * @type {EventForm}
 */
export default memo(EventForm);
