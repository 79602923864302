import { useSnackbar } from 'notistack';
import { getErrorMessage } from './getErrorMessage';
import { useCallback } from 'react';

export default function useCatchError() {
  const { enqueueSnackbar } = useSnackbar();

  return useCallback(
    (error) => {
      console.error(error);

      enqueueSnackbar(getErrorMessage(error), {
        variant: 'error',
      });
    },
    [enqueueSnackbar],
  );
}
