import {
  FormControl,
  Checkbox,
  FormControlLabel,
  FormHelperText,
} from '@mui/material';
import PropTypes from 'prop-types';
import { memo, useCallback } from 'react';

import { COLOR_ERROR } from 'utils/constants/colors';

/**
 * Field that wraps single checkbox and provide displaying error.
 *
 * @param {Object} params Params object
 * @param {String} label Field label
 * @param {Boolean} required Flag makes field required
 * @param {Boolean} disabled Flag makes field disabled
 * @param {Boolean} error Flag turns field in error state
 * @param {String|null} helperText Text under the field
 * @param {Boolean} value Field value
 * @param {(value: Boolean) => void} onChange Change callback
 * @returns {React.ElementType}
 */
const CheckboxField = ({
  label,
  required = false,
  disabled = false,
  error,
  helperText,
  value,
  onChange,
  ...props
}) => {
  const handleChange = useCallback(
    (event) => {
      onChange(event.currentTarget.checked);
    },
    [onChange],
  );

  return (
    <FormControl fullWidth={true} error={error} disabled={disabled}>
      <FormControlLabel
        required={required}
        control={
          <Checkbox
            color={error ? COLOR_ERROR : undefined}
            inputProps={{
              // eslint-disable-next-line react/destructuring-assignment
              'data-testid': props['data-testid'],
            }}
          />
        }
        checked={Boolean(value)}
        disabled={disabled}
        label={label}
        onChange={handleChange}
        sx={{
          margin: 0,
          width: '100%',
        }}
      />
      {helperText && (
        <FormHelperText disabled={disabled}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

CheckboxField.propTypes = {
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  'data-testid': PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

/**
 * @type {CheckboxField}
 */
export default memo(CheckboxField);
