import axios from 'axios';

export default class ServiceCallServices {
  static async getServiceCalls({ model, modelId }) {
    const changeName = () => {
      let name;

      if (model === 'Contact') {
        name = 'contact_id';
      }

      if (model === 'Company') {
        name = 'company_id';
      }

      if (model !== 'Company' && model !== 'Contact') {
        name = model;
      }

      return name;
    };

    const config = {
      filters: [
        { name: 'With_site' },
        {
          name: 'Without_predefined_relations',
        },
        {
          name: changeName(),
          value: modelId,
        },
      ],
      fields: [
        'id',
        'status',
        'scheduled',
        'title',
        'deal_type',
        'date',
        'assigned_to',
        'site',
      ],
      sortBy: 'id',
    };

    const response = await axios.post('serviceCall/filter', config);
    return response.data;
  }

  static async getServiceCall(params) {
    const response = await axios.get('serviceCall', {
      params,
    });
    return response.data;
  }

  static async createServiceCall(data, { signal } = {}) {
    const response = await axios.post('serviceCall/create', data, { signal });
    return response.data;
  }

  static async updateServiceCall(id, data, { signal } = {}) {
    const response = await axios.put(`serviceCall/${id}`, data, { signal });
    return response.data;
  }

  static async deleteServiceCall(id) {
    const response = await axios.delete(`serviceCall/${id}`);
    return response.data;
  }

  static async getTravelFee() {
    const response = await axios.get('setting/client/travel_fee');
    return response.data;
  }

  static async getHourlyRate() {
    const response = await axios.get('setting/client/hourly_charge');
    return response.data;
  }
}
