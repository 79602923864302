import { useQuery } from 'react-query';

import DealService from 'Services/DealService';

export default function useDealsByModelQuery({
  model,
  modelId,
  enabled = true,
  ...otherProps
}) {
  return useQuery({
    queryKey: ['DealsByModel', model, modelId],
    queryFn: ({ signal }) =>
      DealService.getDeals({ model, modelId }, { signal }),
    placeholderData: [],
    keepPreviousData: true,
    enabled: enabled && Boolean(modelId),
    ...otherProps,
  });
}
