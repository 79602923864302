import axios from 'axios';

import { getErrorMessage } from './getErrorMessage';

export default () =>
  async ({ url, method, data, params, headers }, { signal }) => {
    try {
      const result = await axios({
        url,
        method,
        data,
        params,
        headers,
        signal,
      });

      return { data: result.data };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return {
          error: {
            status: error.response?.status,
            data: getErrorMessage(error),
          },
        };
      }

      return {
        error,
      };
    }
  };
