import { jssPreset, StylesProvider, ThemeProvider } from '@material-ui/core';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { SnackbarProvider } from 'notistack';
import { createRoot } from 'react-dom/client';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import { queryClient } from 'reactQuery/queryClient';
import { createTheme } from 'theme';
import 'utils/DataGrid/license';

import App from './App/index';
import config from './config';
import * as serviceWorker from './serviceWorker';
import history from './store/history';
import { persistor, store } from './store/store';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const theme = createTheme();

const root = createRoot(document.getElementById('root'));

const app = (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={theme}>
        <StylesProvider jss={jss}>
          <SnackbarProvider dense={true} maxSnack={3}>
            <QueryClientProvider client={queryClient}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Router
                  basename={config.basename}
                  forceRefresh={true}
                  history={history}
                >
                  <App />
                </Router>
              </LocalizationProvider>
              <ReactQueryDevtools />
            </QueryClientProvider>
          </SnackbarProvider>
        </StylesProvider>
      </ThemeProvider>
    </PersistGate>
  </Provider>
);

root.render(app);

serviceWorker.unregister();
