export const MODEL_TASK = 'Task';
export const MODEL_SERVICE_CALL = 'ServiceCall';
export const MODEL_DEAL = 'Deal';

export const MODELS_PLURAL_NAMES = ['deals', 'service_calls', 'tasks'];

export const MODEL_PLURAL_NAME_TO_SINGULAR = {
  deals: MODEL_DEAL,
  service_calls: MODEL_SERVICE_CALL,
  tasks: MODEL_TASK,
};

export const MODEL_NAMES = {
  [MODEL_DEAL]: 'Deal Installation',
  [MODEL_SERVICE_CALL]: 'Service Call',
  [MODEL_TASK]: 'Task',
};

export const MODEL_OPTIONS = [
  { label: MODEL_NAMES[MODEL_DEAL], value: MODEL_DEAL },
  { label: MODEL_NAMES[MODEL_SERVICE_CALL], value: MODEL_SERVICE_CALL },
  { label: MODEL_NAMES[MODEL_TASK], value: MODEL_TASK },
];

export const MODEL_VIEW_ENDPOINTS = {
  [MODEL_DEAL]: '/deals/overview/{id}',
  [MODEL_SERVICE_CALL]: '/serviceCall/{id}',
  [MODEL_TASK]: '/tasks/{id}',
};
