/* eslint-disable import/prefer-default-export */
import axios from 'axios';

export function getErrorMessage(error) {
  if (axios.isAxiosError(error)) {
    return (
      error?.response?.data?.message ??
      error?.response?.message ??
      error?.message ??
      String(error)
    );
  }

  return error?.message ?? String(error);
}
