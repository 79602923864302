/* eslint-disable react/jsx-props-no-spreading */
import { Alert, Box, Button, Grid, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { memo, useCallback, useEffect, useMemo } from 'react';

import CheckboxField from 'components/CheckboxField';
import InputAutocompleteQuery from 'components/InputAutocompleteQuery';
import useForm from 'hooks/useForm';
import useDealsByModelQuery from 'modules/Deal/hooks/useDealsByModelQuery';
import { COLOR_ERROR, COLOR_PRIMARY } from 'utils/constants/colors';

import {
  FIELD_COMPANY,
  FIELD_CONTACT,
  FIELD_HOURLY_CHARGE,
  FIELD_REASON,
  FIELD_SITE,
  FIELD_SYSTEM,
  FIELD_TAXABLE,
  FIELD_TITLE,
  FIELD_TOTAL_FEE,
  FIELD_WARRANTY,
} from '../constants/fields';

export const INITIAL_VALUES = {
  [FIELD_CONTACT]: null,
  [FIELD_SITE]: null,
  [FIELD_COMPANY]: null,
  [FIELD_SYSTEM]: null,
  [FIELD_HOURLY_CHARGE]: 0,
  [FIELD_TOTAL_FEE]: 0,
  [FIELD_TITLE]: '',
  [FIELD_REASON]: '',
  [FIELD_TAXABLE]: false,
  [FIELD_WARRANTY]: false,
};

const ServiceCallForm = ({
  submitting = false,
  cancelLabel = 'Cancel',
  submitLabel = 'Submit',
  submitLoadingLabel = 'Submitting…',
  errorMessage = null,
  values: initialValues = INITIAL_VALUES,
  errors: initialErrors = {},
  contactFieldOptions = [],
  siteFieldOptions = [],
  companyFieldOptions = [],
  systemFieldOptions = [],
  onChange,
  onCancel,
  onSubmit,
}) => {
  // Hooks

  const form = useForm({
    initialValues,
    initialErrors,
    onChange,
    onSubmit,
  });

  useDealsByModelQuery({
    model: 'contact',
    modelId: form.values?.[FIELD_CONTACT],
    cacheTime: 0,
    staleTime: 0,
    onSuccess: (_deals) => {
      if (_deals.length > 0) {
        form.setFieldValue(FIELD_SITE, _deals.at(0).site_id);
        form.setFieldValue(FIELD_COMPANY, _deals.at(0).company_id);
        form.setFieldValue(FIELD_SYSTEM, _deals.at(0).systems.at(0)?.id);
      }
    },
  });

  // Handlers

  const handleCancelClick = useCallback(() => onCancel(), [onCancel]);

  // Computable

  const siteFieldOptionsParams = useMemo(
    () => ({
      contact_id: form.values[FIELD_CONTACT],
    }),
    [form.values],
  );

  const companyFieldOptionsParams = useMemo(
    () => ({
      contact_id: form.values[FIELD_CONTACT],
    }),
    [form.values],
  );

  const systemFieldOptionsParams = useMemo(
    () => ({
      contact_id: form.values[FIELD_CONTACT],
      company_id: form.values[FIELD_COMPANY],
      site_id: form.values[FIELD_SITE],
    }),
    [form.values],
  );

  const submitDisabled = useMemo(() => {
    if (submitting) {
      return true;
    }

    return (
      !form.values[FIELD_CONTACT] ||
      !form.values[FIELD_SITE] ||
      !form.values[FIELD_TITLE] ||
      !form.values[FIELD_REASON]
    );
  }, [form.values, submitting]);

  // Effects

  useEffect(() => {
    if (form.values[FIELD_CONTACT] !== form.prevValues[FIELD_CONTACT]) {
      form.setFieldValue(FIELD_SITE, null);
    }
  }, [form, form.prevValues, form.values]);

  // Render

  return (
    <Box component="form" onSubmit={form.handleSubmit}>
      <Grid container={true} spacing={2}>
        {errorMessage && (
          <Grid item={true} md={12} xs={12}>
            <Alert severity={COLOR_ERROR}>{errorMessage}</Alert>
          </Grid>
        )}
        <Grid item={true} md={6} xs={12}>
          <InputAutocompleteQuery
            disabled={submitting}
            queryKeyPrefix="service_call_contact"
            label="Contact"
            required={true}
            placeholder="Search Contacts…"
            options={contactFieldOptions}
            value={form.values[FIELD_CONTACT] ?? null}
            onChange={form.getFieldValueChangeHandler(FIELD_CONTACT)}
            {...form.getMuiFieldErrorProps(FIELD_CONTACT)}
            data-testid="contact_field"
          />
        </Grid>
        <Grid item={true} md={6} xs={12}>
          <InputAutocompleteQuery
            disabled={submitting}
            queryKeyPrefix="service_call_site"
            label="Site"
            required={true}
            placeholder="Search Sites…"
            options={siteFieldOptions}
            optionsParams={siteFieldOptionsParams}
            value={form.values[FIELD_SITE] ?? null}
            onChange={form.getFieldValueChangeHandler(FIELD_SITE)}
            {...form.getMuiFieldErrorProps(FIELD_SITE)}
            data-testid="site_field"
          />
        </Grid>
        <Grid item={true} md={6} xs={12}>
          <InputAutocompleteQuery
            disabled={submitting}
            queryKeyPrefix="service_call_company"
            label="Company"
            placeholder="Search Companies…"
            options={companyFieldOptions}
            optionsParams={companyFieldOptionsParams}
            value={form.values[FIELD_COMPANY] ?? null}
            onChange={form.getFieldValueChangeHandler(FIELD_COMPANY)}
            {...form.getMuiFieldErrorProps(FIELD_COMPANY)}
            data-testid="company_field"
          />
        </Grid>
        <Grid item={true} md={6} xs={12}>
          <InputAutocompleteQuery
            disabled={submitting}
            queryKeyPrefix="service_call_system"
            label="System"
            placeholder="Search Systems…"
            options={systemFieldOptions}
            optionsParams={systemFieldOptionsParams}
            value={form.values[FIELD_SYSTEM] ?? null}
            onChange={form.getFieldValueChangeHandler(FIELD_SYSTEM)}
            {...form.getMuiFieldErrorProps(FIELD_SYSTEM)}
            data-testid="system_field"
          />
        </Grid>
        <Grid item={true} md={6} xs={12}>
          <TextField
            disabled={submitting}
            fullWidth={true}
            label="Hourly Charge"
            type="number"
            variant="outlined"
            inputProps={{
              'data-testid': 'hourly_charge_field',
            }}
            {...form.getFieldProps(FIELD_HOURLY_CHARGE)}
            {...form.getMuiFieldErrorProps(FIELD_HOURLY_CHARGE)}
          />
        </Grid>
        <Grid item={true} md={6} xs={12}>
          <TextField
            disabled={submitting}
            fullWidth={true}
            label="Total Fee"
            type="number"
            variant="outlined"
            inputProps={{
              'data-testid': 'total_fee_field',
            }}
            {...form.getFieldProps(FIELD_TOTAL_FEE)}
            {...form.getMuiFieldErrorProps(FIELD_TOTAL_FEE)}
          />
        </Grid>
        <Grid item={true} md={12} xs={12}>
          <TextField
            disabled={submitting}
            fullWidth={true}
            required={true}
            label="Title"
            variant="outlined"
            inputProps={{
              'data-testid': 'title_field',
            }}
            {...form.getFieldProps(FIELD_TITLE)}
            {...form.getMuiFieldErrorProps(FIELD_TITLE)}
          />
        </Grid>
        <Grid item={true} md={12} xs={12}>
          <TextField
            disabled={submitting}
            fullWidth={true}
            multiline={true}
            required={true}
            rows={4}
            label="Reason"
            variant="outlined"
            inputProps={{
              'data-testid': 'reason_field',
            }}
            {...form.getFieldProps(FIELD_REASON)}
            {...form.getMuiFieldErrorProps(FIELD_REASON)}
          />
        </Grid>
        <Grid item={true} md={6} xs={12}>
          <CheckboxField
            disabled={submitting}
            label="Taxable"
            value={form.values[FIELD_TAXABLE] ?? false}
            onChange={form.getFieldValueChangeHandler(FIELD_TAXABLE)}
            {...form.getMuiFieldErrorProps(FIELD_TAXABLE)}
            data-testid="taxable_field"
          />
        </Grid>
        <Grid item={true} md={6} xs={12}>
          <CheckboxField
            disabled={submitting}
            label="Warranty"
            value={form.values[FIELD_WARRANTY] ?? false}
            onChange={form.getFieldValueChangeHandler(FIELD_WARRANTY)}
            {...form.getMuiFieldErrorProps(FIELD_WARRANTY)}
            data-testid="warranty_field"
          />
        </Grid>
        <Grid
          item={true}
          md={12}
          xs={12}
          display="flex"
          gap={2}
          justifyContent="space-between"
        >
          <Button
            disabled={submitting}
            type="button"
            variant="outlined"
            color={COLOR_PRIMARY}
            onClick={handleCancelClick}
            data-testid="cancel_button"
          >
            {cancelLabel}
          </Button>
          <Button
            disabled={submitDisabled}
            type="submit"
            variant="contained"
            color={COLOR_PRIMARY}
            data-testid="submit_button"
          >
            {submitting ? submitLoadingLabel : submitLabel}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

ServiceCallForm.propTypes = {
  submitting: PropTypes.bool,
  cancelLabel: PropTypes.string,
  submitLabel: PropTypes.string,
  submitLoadingLabel: PropTypes.string,
  errorMessage: PropTypes.string,
  values: PropTypes.shape({
    [FIELD_CONTACT]: PropTypes.number,
    [FIELD_SITE]: PropTypes.number,
    [FIELD_COMPANY]: PropTypes.number,
    [FIELD_SYSTEM]: PropTypes.number,
    [FIELD_HOURLY_CHARGE]: PropTypes.number,
    [FIELD_TOTAL_FEE]: PropTypes.number,
    [FIELD_TITLE]: PropTypes.string,
    [FIELD_REASON]: PropTypes.string,
    [FIELD_TAXABLE]: PropTypes.bool,
    [FIELD_WARRANTY]: PropTypes.bool,
  }),
  errors: PropTypes.shape({
    [FIELD_CONTACT]: PropTypes.string,
    [FIELD_SITE]: PropTypes.string,
    [FIELD_COMPANY]: PropTypes.string,
    [FIELD_SYSTEM]: PropTypes.string,
    [FIELD_HOURLY_CHARGE]: PropTypes.string,
    [FIELD_TOTAL_FEE]: PropTypes.string,
    [FIELD_TITLE]: PropTypes.string,
    [FIELD_REASON]: PropTypes.string,
    [FIELD_TAXABLE]: PropTypes.string,
    [FIELD_WARRANTY]: PropTypes.string,
  }),
  contactFieldOptions: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
      }),
    ),
  ]),
  siteFieldOptions: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
      }),
    ),
  ]),
  companyFieldOptions: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
      }),
    ),
  ]),
  systemFieldOptions: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
      }),
    ),
  ]),
  onChange: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

/**
 * @type {ServiceCallForm}
 */
export default memo(ServiceCallForm);
