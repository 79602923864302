import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import clientReducer from 'store/Client/clientreducer';
import subscriptionReducer from 'store/Subscriptions/subscriptionReducer';
import siteReducer from 'modules/Site/Site.reducer';
import companyReducer from 'modules/Company/Company.reducer';
import contactReducer from 'modules/Contact/Contact.reducer';
import reducer from 'store/reducer';
import commonReducer from 'store/common/commonreducer';
import dealReducer from 'store/Deal/dealReducer';
import taskReducer from 'modules/Task/Task.reducer';
import scheduler from 'modules/Scheduler/Scheduler.reducer';
import serviceCallReducer from 'modules/ServiceCall/ServiceCall.reducer';
import eventReducer from 'modules/EventLog/Events.reducer';
import statusReducer from 'modules/EventLog/Events.Status.reducer';
import newDealReducer from './NewDeal/newDealReducer';
import newInvoice from 'modules/NewInvoice/NewIncoiceForm.reducer';
import loginReducer from 'store/Login/loginReducer';
import userReducer from 'store/User/userReducer';
import productReducer from 'store/Product/productReducer';
import inventoryReducer from 'store/Inventory/inventoryReducer';
import brandReducer from 'store/Brand/brandReducer';
import serviceReducer from 'store/Service/serviceReducer';
import bgReducer from 'store/BackgroundCheck/reducer';
import notesReducer from 'store/Notes/reducer';
import pkgReducer from 'store/Packages/reducer';
import newPaymentReducer from 'store/NewPayments/reducer';
import invoiceReducer from 'store/Invoice/invoiceReduce';
import dispatching from 'modules/Event/slices/dispatching';
import dispatchApi from 'modules/Event/api/dispatching';
import serviceCallApi from 'modules/ServiceCall/api/serviceCall';
import taskApi from 'modules/Task/api/task';

const persistConfig = {
  key: 'root',
  storage,
};

export default combineReducers({
  reducer,
  common: commonReducer,
  login: persistReducer(persistConfig, loginReducer),
  client: clientReducer,
  product: productReducer,
  site: siteReducer,
  company: companyReducer,
  contact: contactReducer,
  user: userReducer,
  inventory: inventoryReducer,
  deal: dealReducer,
  newDeal: newDealReducer,
  brand: brandReducer,
  bgCheck: bgReducer,
  serviceCall: serviceCallReducer,
  service: serviceReducer,
  subscription: subscriptionReducer,
  notes: notesReducer,
  task: taskReducer,
  packages: pkgReducer,
  payment: newPaymentReducer,
  invoice: invoiceReducer,
  scheduler,
  event: eventReducer,
  eventStatuses: statusReducer,
  newInvoice,
  dispatching: dispatching.reducer,
  dispatchingApi: dispatchApi.reducer,
  serviceCallApi: serviceCallApi.reducer,
  taskApi: taskApi.reducer,
});
