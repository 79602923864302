import axios from 'axios';

export const ENDPOINT_EVENT_GET_ALL_V2 = '/event/getAllV2';
export const ENDPOINT_EVENT_CREATE = '/event/{model}/{model_id}';
export const ENDPOINT_EVENT_UPDATE = '/event/{id}';
export const ENDPOINT_EVENT_GET_V2 = '/event/{id}/v2';
export const ENDPOINT_UNASSIGNED = '/dispatchByDate';

export default class EventService {
  static async list(params, { signal } = {}) {
    return (await axios.get(ENDPOINT_EVENT_GET_ALL_V2, { params, signal })).data
      .data;
  }

  static async listUnassigned({ signal } = {}) {
    return (await axios.post(ENDPOINT_UNASSIGNED, { signal })).data;
  }

  static async get(eventId, { signal }) {
    return (
      await axios.get(ENDPOINT_EVENT_GET_V2.replace('{id}', eventId), {
        signal,
      })
    ).data;
  }

  static async create(
    { model_id: modelId, model, id, ...data },
    { signal } = {},
  ) {
    return (
      await axios.post(
        ENDPOINT_EVENT_CREATE.replace('{model}', model).replace(
          '{model_id}',
          modelId,
        ),
        data,
        {
          signal,
        },
      )
    ).data;
  }

  static async update(id, data, { signal } = {}) {
    return (
      await axios.put(ENDPOINT_EVENT_UPDATE.replace('{id}', id), data, {
        signal,
      })
    ).data;
  }
}
