import axios from 'axios';

export default class TaskServices {
  static async getTasks({ params, model, modelId }) {
    const query = {
      model: 'Task',
      queries: [
        {
          field: `${model}_id`,
          value: modelId,
        },
      ],
      orderBy: 'desc',
      orderByColumn: 'updated_at',
    };

    const response = await axios.post('v2/search', query, params);
    return response.data;
  }

  static async getTask(params) {
    const response = await axios.get('task', { params });
    return response.data;
  }

  static async createTask(data) {
    const response = await axios.post('tasks', data);
    return response.data;
  }

  static async updateTask(id, data) {
    const response = await axios.put(`task/${id}`, {
      data,
    });
    return response.data;
  }

  static async deleteTask(id) {
    const response = await axios.delete(`task/${id}`);
    return response.data;
  }
}
