import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistStore } from 'redux-persist';

import dispatchingApi from 'modules/Event/api/dispatching';
import serviceCallApi from 'modules/ServiceCall/api/serviceCall';
import taskApi from 'modules/Task/api/task';

// eslint-disable-next-line import/no-cycle
import rootReducer from './combineReducers';
import listenerMiddleware from './listenerMiddleware';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const customMiddleware = (store) => (next) => (action) => next(action);

export const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(
      listenerMiddleware.middleware,
      thunk,
      customMiddleware,
      dispatchingApi.middleware,
      serviceCallApi.middleware,
      taskApi.middleware,
    ),
  ),
);

export const testStore = (preloadedState) =>
  createStore(
    rootReducer,
    preloadedState,
    composeEnhancers(
      applyMiddleware(
        listenerMiddleware.middleware,
        thunk,
        customMiddleware,
        dispatchingApi.middleware,
        serviceCallApi.middleware,
        taskApi.middleware,
      ),
    ),
  );

export const persistor = persistStore(store);
