export const FIELD_ID = 'id';
export const FIELD_CONTACT = 'contact_id';
export const FIELD_CONTACT_NAME = 'contact_name';
export const FIELD_SITE_ID = 'site_id';
export const FIELD_COMPANY = 'company_id';
export const FIELD_COMPANY_NAME = 'company_name';
export const FIELD_SYSTEM = 'subtype';
export const FIELD_HOURLY_CHARGE = 'hourly_charge';
export const FIELD_TRAVEL_FEE = 'travel_fee';
export const FIELD_TITLE = 'title';
export const FIELD_NOTES = 'notes';
export const FIELD_TAXABLE = 'taxable';
export const FIELD_WARRANTY = 'warranty';
export const FIELD_TYPE = 'model';
export const FIELD_CITY = 'city';
export const FIELD_PHONE = 'phone';
export const FIELD_ASSIGNED = 'assigned';
export const FIELD_START = 'start';
export const FIELD_END = 'end';
export const FIELD_NOTIFY_TECH = 'notify_tech';
export const FIELD_NOTIFY_CUSTOMER = 'notify_customer';
export const FIELD_TECHNICIAN_USERS = 'users';
export const FIELD_MODEL = 'model';
export const FIELD_MODEL_ID = 'model_id';
export const FIELD_SEPARATE_EVENT_PER_TECH = 'separate_event_per_tech';
