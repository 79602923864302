import axios from 'axios';

export default class DealService {
  static async getDeals({ model, modelId }, options = {}) {
    let query;

    if (model && modelId) {
      query = {
        filter: {
          [`${model.toLowerCase()}_id`]: {
            '=': modelId,
          },
          stage_of_sale: {
            '>': 2,
          },
        },
      };
    } else {
      query = {
        filter: {
          id: {
            '=': modelId,
          },
          stage_of_sale: {
            '>': 2,
          },
        },
      };
    }

    const response = await axios.post('deals', query, {
      signal: options.signal,
    });

    return response.data;
  }

  static async getOptimizedDeals({ model, modelId, page, per_page: perPage }) {
    let query;

    if (model && modelId) {
      query = {
        filter: {
          [`${model.toLowerCase()}_id`]: {
            '=': modelId,
          },
          stage_of_sale: {
            '>': 2,
          },
        },
      };
    } else {
      query = {
        filter: {
          id: {
            '=': modelId,
          },
          stage_of_sale: {
            '>': 2,
          },
        },
      };
    }

    const res = await axios.post(
      `deals/customer?page=${page}&per_page=${perPage}`,
      query,
    );

    return res.data;
  }

  static async getDeal(params) {
    const response = await axios.get('deals', { params });
    return response.data;
  }

  static async createDeal(data) {
    const response = await axios.post('deals', {
      data,
    });
    return response.data;
  }

  static async updateDeal(id, data) {
    const response = await axios.put('deals/id', {
      data,
    });
    return response.data;
  }

  static async deleteDeal(id) {
    const response = await axios.delete(`deals/${id}`);
    return response.data;
  }

  static async getPossibleStatuses() {
    const response = await axios.get('deals/possible-statuses');
    return response.data;
  }

  static async getPossibleTypes() {
    const response = await axios.get('dealType');
    return response.data;
  }

  static async lookForDeals(lookupData) {
    const response = await axios.post('deal/lookup', lookupData);
    return response.data;
  }

  static async getSalesPersonInfo(dealId) {
    const response = await axios.get(`deal/${dealId}/info/sales-person`);
    return response.data;
  }
}
