import { useFormik } from 'formik';
import { useCallback, useEffect } from 'react';
import isEqual from 'lodash/isEqual';

import usePrevious from './usePrevious';

/**
 * Hook that extends useFormik hook and adds project-related function.
 * {@link ../../docs/hooks/useForm.md Documentation}
 *
 * @param {import('formik').FormikConfig & { onChange: (values, prevValues) => void }} props
 * @returns {({ getFieldValueChangeHandler: (field: String, (value: any) => any) => Function, getMuiFieldErrorProps: (field: String) => { error: Boolean, helperText: String|null }, prevValues: Object }) & ReturnType<import('formik').useFormik>}
 */
export default function useForm({ onChange, ...props } = {}) {
  // Hooks

  const form = useFormik(props);

  const prevFormValues = usePrevious(form.values);

  const prevPropsValues = usePrevious(props.values);

  // Methods

  /**
   * Function that creates handler for input that accepts only value.
   * Formik doesn't have such method, but it simplify code and free developer
   * of creating handler for each field individually.
   *
   * @param {String} field Field name
   * @param {(value: any) => any} transformValue Transform value function (optional)
   * @returns {Function}
   */
  const getFieldValueChangeHandler = useCallback(
    (field, transformValue = (value) => value) =>
      (value) =>
        form.setFieldValue(field, transformValue(value)),
    [form],
  );

  /**
   * MUI specific function that returns error props for field.
   *
   * @param {String} field Field name
   * @returns {{ error: Boolean, helperText: String|null }}
   */
  const getMuiFieldErrorProps = useCallback(
    (field) => ({
      error: Boolean(form.errors[field]),
      helperText: form.errors[field] ? form.errors[field] : null,
    }),
    [form.errors],
  );

  // Effects

  useEffect(() => {
    if (onChange) {
      onChange(form.values, prevFormValues);
    }
  }, [form.values, onChange, prevFormValues]);

  useEffect(() => {
    if (!isEqual(props.values, prevPropsValues)) {
      form.setValues((values) => ({ ...values, ...props.values }));
    }
  }, [form, form.values, prevPropsValues, props.values]);

  // Returns

  return {
    ...form,
    prevValues: prevFormValues,
    getFieldValueChangeHandler,
    getMuiFieldErrorProps,
  };
}
