import { createApi } from '@reduxjs/toolkit/query/react';

import TaskService from 'Services/TaskService';
import axiosBaseQuery from 'utils/axios/axiosBaseQuery';
import { getErrorMessage } from 'utils/axios/getErrorMessage';
import { RequestValidationFailedError } from 'utils/errors';

export default createApi({
  reducerPath: 'taskApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    create: build.mutation({
      queryFn: async (data, { signal }) => {
        try {
          return {
            data: await TaskService.createTask(
              {
                contact: { id: data.contact_id },
                site: { id: data.site_id },
                company: data.company_id ? { id: data.company_id } : null,
                description: data.description,
                schedule: [],
                status: data.status,
                priority: data.priority,
                title: data.title,
                deal_system_id: data.deal_system_id,
              },
              { signal },
            ),
          };
        } catch (error) {
          throw new RequestValidationFailedError(getErrorMessage(error));
        }
      },
    }),

    update: build.mutation({
      queryFn: async ({ id, ...data }, { signal }) => {
        try {
          return {
            data: await TaskService.updateTask(
              id,
              {
                contact: { id: data.contact_id },
                site: { id: data.site_id },
                deal: {
                  id: data.deal_id,
                },
                company: data.company_id ? { id: data.company_id } : null,
                reason: data.reason,
                products: [],
                warranty: data.warranty,
                taxable: data.taxable,
                charges: {
                  hourly_charge: data.hourly_charge,
                  travel_fee: data.travel_fee,
                },
                deal_id: data.deal_id,
                deal_system_id: data.deal_system_id,
              },
              {
                signal,
              },
            ),
          };
        } catch (error) {
          throw new RequestValidationFailedError(getErrorMessage(error));
        }
      },
    }),
  }),
});
